import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—July 2010" />
	<NewsHeader />
    <h2>July 2010</h2>




	<h3 className="blogdate">
	Tuesday, July 20, 2010
	</h3>

	<h4 className="blogitemtitle">
	Recent Developments - a return
	</h4>
	   <div className="blogitembody">
	   <p>
	Here we go again, Wellington! One more chance to catch <i>Jesse Rivest
	and the Recent Developments</i> (Brendan Schenk on
	banjo/accordion/mandolin, Peter Cogswell on neat-o percussion)!
	</p>
	<p>
	Check my podcast (most recent episode) for some audio clips of our
	previous show at Mojo Invincible and then come on down and party with us
	like it's Matthew Pickering's birthday!
	</p>
	<ul>
	<li>
	8:00 pm, July 31, <b>Katipo Cafe</b>, 76 Willis Street, Wellington,
	New Zealand
	</li>
	<li>
	<i>Jesse Rivest and the Recent Developments</i>, followed by <i>The
	Town Crier</i> (a choir!), followed by birthday boy <i>Matthew
	Pickering</i> (and friends)
	</li>
	<li>
	If you're Facebook crazy, <a href="http://www.facebook.com/event.php?eid=130263170345747&ref=mf">here's
	the event</a>
	</li>
	</ul>
	<p>
	Hope to see you there!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 15:10 +1200</p>
	   </div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
